import {Worker} from "../util";
import FormsService from "../forms/FormsService";
import ApiService from "../api/ApiService";
import {CreateDatasourceCredentialsRequest, Datasource, DatasourceCredentials} from "../../datasources";
import DialogService from "../dialogs/DialogService";
import * as openpgp from "openpgp";


export interface DatasourceCredentialsFE extends DatasourceCredentials {
    createPending?: boolean
    deletePending?: boolean
    activatePending?: boolean
    testPending?: boolean
    testResult?: any
    errorMsg?: string
}

export interface DatasourceFE extends Datasource {
    createPending?: boolean
    deletePending?: boolean
    activatePending?: boolean
    testPending?: boolean
    testResult?: any;
    errorMsg?: string
}

export interface CreateDatasourceCredentialsRequestFE extends CreateDatasourceCredentialsRequest {
    createPending?: boolean
    testPending?: boolean
    testResult?: any;
    errorMsg?: string
}


export default class FormsDatasourceSettingsCtrl {
    static $resolve = {
        id: ($stateParams) => $stateParams.id
    };

    public datasource: DatasourceFE;
    public forms;
    public dbTypeOptions = [
        {label: "MySQL", value: "MYSQL"},
        {label: "MariaDB", value: "MARIADB"},
        {label: "PostgreSQL", value: "PGSQL"},
        {label: "Microsoft SQL Server", value: "MSSQL"},
        {label: "Azure Synapse Analytics", value: "SYNAPSE"},
        {label: "Snowflake", value: "SNOWFLAKE"},
    ];

    public CredentialsTypeOptions = getCredentialTypeOptions();

    public dbSettingsDirty = false;

    public newCredentials: CreateDatasourceCredentialsRequestFE = {
        note: "",
        type: "up",
        username: "",
        keypairType: "rsa2048",
        password: "",
        encryptedPassword: ""
    }
    public showAddNewCredentials: boolean = false;

    constructor(private id, private FormsService: FormsService, private DialogService: DialogService, private ApiService: ApiService,) {
        this.saveDatasource = Worker(this.saveDatasource.bind(this));
        this.createCredentials = Worker(this.createCredentials.bind(this));
        this.deleteCredentials = Worker(this.deleteCredentials.bind(this));
        this.activateCredentials = Worker(this.activateCredentials.bind(this));
        this.testDatasourceCredentials = Worker(this.testDatasourceCredentials.bind(this));
        this.init();
    }

    async init() {
        await this.loadDatasource();
        await this.loadForms();
    }

    async loadDatasource() {
        if (this.id === "newId") {
            this.datasource = {
                activeCredentialsId: "",
                connectionString: "",
                id: "",
                metadata: {db: {type: null, database: null, hostname: null, schema: null, warehouse: null}},
                name: "",
                note: "",
                owner: "",
                type: "sql"
            }
            return;
        } else {
            this.datasource = (await this.ApiService.get("/api/datasources/" + this.id)).data
        }
        this.CredentialsTypeOptions = getCredentialTypeOptions(this.datasource.metadata.db.type);
    }

    async loadForms() {
        this.forms = ((await this.ApiService.get("/api/forms")).data).filter(form => form.config?.useDatasource && form.config?.datasource === this.id)
    }

    async testDatasourceCredentials(cr) {
        cr.errorMsg = undefined
        cr.testResult = undefined
        try {
            cr.testPending = true;
            cr.testResult = (await this.ApiService.get("/api/datasources/" + this.id + "/test?credentialsId=" + cr.id)).data
        } catch (e) {
            cr.errorMsg = e.statusText;
            console.log("testDatasourceCredentials error", e)
        }
        cr.testPending = false;
    }

    isDatabase() {
        return true;
    }

    getCredentialsTypeLabelByValue(value) {
        const option = this.CredentialsTypeOptions.find(option => option.value === value);
        return option ? option.label : undefined;
    }

    async saveDatasource() {
        if (!this.datasource.name) throw "Data source name is required.";
        if (this.id === "newId") {
            this.datasource.activatePending = true;
            this.datasource = (await this.ApiService.post("/api/datasources", this.datasource)).data
            this.id = this.datasource.id;
        } else {
            this.datasource = (await this.ApiService.put("/api/datasources/" + this.id, this.datasource)).data
        }
        this.dbSettingsDirty = false;
        window.location.hash = "#!/forms/datasource/" + this.id + "/settings";
        void this.loadDatasource();
    }


    async encryptPassword(password: string): Promise<string> {
        let keychain: openpgp.Key;
        try {
            let key = await this.FormsService.getDatasourcePublicKey(this.id);
            keychain = await openpgp.readKey({armoredKey: key});
        } catch (e) {
            throw new Error("Failed to load public key for database credentials encryption");
        }
        let encrypted = await openpgp.encrypt({
            message: await openpgp.createMessage({text: password}),
            encryptionKeys: keychain
        })
        return encrypted.toString();
    }

    async createCredentials() {
        this.newCredentials.errorMsg = undefined;
        this.newCredentials.createPending = true
        if (this.newCredentials.password && this.newCredentials.password != "") {
            this.newCredentials.encryptedPassword = await this.encryptPassword(this.newCredentials.password);
        }

        this.datasource = (await this.ApiService.post("/api/datasources/" + this.id + "/credentials", this.newCredentials)).data
        this.newCredentials = {
            note: "",
            type: "up",
            username: "",
            keypairType: "rsa2048",
            password: "",
            encryptedPassword: ""
        }
        this.showAddNewCredentials = false;
        void this.loadDatasource();
    }


    async deleteCredentials(cr: DatasourceCredentialsFE) {
        let result = await this.DialogService.confirm(
            `Remove credentials${cr.note ? ` ("${cr.note}")` : ``} ? Credentials will be deleted.`
        );
        if (result) {
            cr.deletePending = true;
            try {
                await this.ApiService.delete("/api/datasources/" + this.id + "/credentials/" + cr.id);
                await this.DialogService.success("Credentials have been deleted successfully.");
            } catch (e) {
                cr.errorMsg = e.data.message;
                cr.deletePending = false;
                return;
            }
            cr.deletePending = false;
            await this.loadDatasource();
        }
    }


    async activateCredentials(cr: DatasourceCredentialsFE) {
        let result = await this.DialogService.confirm(
            `Activate selected credentials${cr.note ? ` ("${cr.note}")` : ``} ?
            Credentials will be activated immediately.`
        );
        if (result) {
            cr.activatePending = true;
            try {
                this.datasource.activeCredentialsId = cr.id;
                await this.ApiService.put("/api/datasources/" + this.id, this.datasource);
                await this.DialogService.success("Credentials have been activated successfully.");
            } catch (e) {
                cr.errorMsg = e.data.message;
                cr.activatePending = false;
                return;
            }
            cr.activatePending = false;
            await this.loadDatasource();
        }
    }

    async resetNewCredentials() {
        this.newCredentials = {
            note: "",
            type: "snowflake:keypair",
            username: "",
            keypairType: "rsa2048",
            password: "",
            encryptedPassword: ""
        }
        this.showAddNewCredentials = false;
    }


}


function getCredentialTypeOptions(dbType?: string) {
    return [
        {label: "Username & password", value: "up"},
        ...(dbType === "SNOWFLAKE" ? [{label: "Snowflake key-pair", value: "snowflake:keypair"}] : [])
    ]
}