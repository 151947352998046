import SettingsService from "./SettingsService";
import AuthService from "../auth/AuthService";
import config from "../config";

let destinationUrl: string;

export function setDestinationUrl(url: string) {
    destinationUrl = url;
}

export default class SwitchTenantCtrl {
    static $resolve = {
        profile: (SettingsService: SettingsService) => SettingsService.getCachedProfile(),
    }

    constructor(public profile, private SettingsService: SettingsService, private $state, private AuthService: AuthService) {
    }

    async switchTenant(tenant: { id: number, qlikCloudDomain: string }) {
        const user = this.AuthService.getUser();
        if (user && "isIntegratedLogin" in user && user.isIntegratedLogin) {
            this.$state.go("login-qlik", {qlikCloudDomain: tenant.qlikCloudDomain})
        } else {
            await this.SettingsService.switchTenant(tenant.id);
            window.location.hash = destinationUrl || ("#!" + (config.afterLoginPage || config.home || '/store?sort=-creationDate'));
        }
        setDestinationUrl(undefined);
    }
}
