import SettingsService from "../settings/SettingsService";
import {Worker} from "../util";
import {SQL_FORM_TYPES} from "../../util/consts";
import FormsService from "./FormsService";
import DialogService from "../dialogs/DialogService";
import ApiService from "../api/ApiService";
import {UserProfile} from "../../user";
import AuthService from "../auth/AuthService";
import {Datasource} from "../../datasources";

export const typesList = {
    general: "General",
    'general-mongo': "General+",
    "general-sql": "General SQL",
    "planning": "Planning",
    "planning-mongo": "Planning+",
    "planning-sql": "Planning SQL",
    commentobj: 'Comment object',
    'commentobj-mongo': 'Comment object+',
    "commentobj-sql": "Comment object SQL",
}


export function changelogString(form) {
    if (!form) return;
    if (form.changelog == null) return (SQL_FORM_TYPES.includes(form.type) ? "Disabled" : "Enabled")
    return form.changelog ? "Enabled" : "Disabled";
}

export default class FormsCtrl {
    static $resolve = {
        profile: (SettingsService: SettingsService) => SettingsService.getCachedProfile().then(null, () => null)
    };

    public forms = [];
    public newFormData;
    public err;
    public r;
    public types;
    public regions = [];
    public regionsCount;
    public qlikclouddomain;
    public periodProgres;
    public search = {type: ''}
    public defaultFormsType;
    public defaultCommentFormsType;
    public defaultPlanningFormsType;
    public changelogString = changelogString;
    public formsConfig;
    public formsLimit: number;
    public dbAllowed: boolean;
    public planningAllowed: boolean;
    public clientId: string;
    public datasources: Datasource[] = [];
    public datasourcesErr;
    public qlikToken;

    constructor(
        private ApiService: ApiService,
        private DialogService: DialogService,
        private FormsService: FormsService,
        AuthService: AuthService,
        private profile: UserProfile | null,
        public config
    ) {
        if (!profile) {
            AuthService.login();
            throw new Error("Please, log in.");
        }
        this.formsLimit = profile.tenant.formLimit;
        this.dbAllowed = profile.tenant.formsDb;
        this.planningAllowed = profile.tenant.userPlanLimit > 0;

        this.load = Worker(this.load.bind(this));
        this.loadDatasources = Worker(this.loadDatasources.bind(this))
        this.createNewForm = Worker(this.createNewForm);
        this.synchronizeGroups = Worker(this.synchronizeGroups.bind(this));
        void this.load();
        void this.getQlikclouddomain();
        void this.loadDatasources();
    }

    async loadDatasources() {
        this.datasources = (await this.ApiService.get("/api/datasources")).data as Datasource[];
    }

    async load() {
        this.forms = (await this.ApiService.get("/api/forms")).data

        this.calculatePeriodFactor();

        if (this.profile.isAdmin || this.profile.isFormsAdmin) {
            this.formsConfig = (await this.ApiService.get("/api/forms/config")).data
            this.clientId = this.formsConfig.clientId;
            this.regions = this.formsConfig.allowedFormsRegions;
            this.defaultFormsType = this.formsConfig.defaultFormsType ? this.formsConfig.defaultFormsType : 'general'
            this.defaultCommentFormsType = this.formsConfig.defaultCommentFormsType ? this.formsConfig.defaultCommentFormsType : 'commentobj'
            this.defaultPlanningFormsType = this.formsConfig.defaultPlanningFormsType ? this.formsConfig.defaultPlanningFormsType : 'planning'

            this.types = this.defaultFormsType == 'general' ? {general: "General"} : {'general-mongo': "General+"}

            if (this.dbAllowed) this.types["general-sql"] = "General SQL";

            if (this.planningAllowed) {
                if (this.defaultPlanningFormsType == 'planning-mongo') {
                    this.types["planning-mongo"] = "Planning+"
                } else {
                    this.types["planning"] = "Planning"
                }

                if (this.dbAllowed) {
                    this.types["planning-sql"] = "Planning SQL"
                }

            }

            if (this.defaultCommentFormsType == 'commentobj') {
                this.types['commentobj'] = 'Comment object'
            } else {
                this.types['commentobj-mongo'] = 'Comment object+'
            }

            if (this.dbAllowed) this.types["commentobj-sql"] = "Comment object SQL";

            this.newFormData = {
                type: this.defaultFormsType,
                changelog: true,
                region: Object.keys(this.regions)[0],
                description: "",
            };
        }
        this.regionsCount = Object.keys(this.regions).length;
    }

    async createNewForm() {
        this.err = this.r = "";
        if (this.regionsCount == 0) {
            "This portal has no allowed Forms"
            return;
        }

        this.newFormData.Pending = true;
        try {
            var form = (await this.ApiService.post("/api/forms", this.newFormData)).data;
            this.r = "New form with id " + form + " created successfully. Now you can use it in Qlik Sense Business Cloud"
            //console.log(form)
        } catch (e) {
            this.err = e.data.message;
        }

        this.newFormData.Pending = false;

        this.newFormData = {
            type: this.defaultFormsType,
            changelog: true,
            region: Object.keys(this.regions)[0],
            description: "",
        };

        await this.load();
    }

    async deleteForm(form) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Remove " + form.formId + " ? Form instance and form data will be deleted.");
        if (result) {
            form.deletePending = true;
            try {
                this.r = (await this.ApiService.delete("/api/forms/" + form.formId)).data;
                this.r = "Form with id " + form.formId + " was deleted successfully."
            } catch (e) {
                this.err = e.data.message;
                form.deletePending = false;
                return;
            }

            await this.load();
        }
    }

    downloadData(form) {
        this.ApiService.postForm("/api/forms/" + form.formId + "/download", null);
    }

    async deleteData(form) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Remove data from form" + form.formId + " ? Form data will be deleted.");
        if (result) {
            form.deleteDataPending = true;
            try {
                await this.ApiService.delete("/api/forms/" + form.formId + "/data");
            } catch (e) {
                this.err = e.data.message;
                form.deleteDataPending = false;
                return;
            }
            form.deleteDataPending = false;
            this.r = "Data from form id " + form.formId + " was deleted successfuly."
            await this.load();
        }
    }

    async getQlikclouddomain() {
        this.qlikclouddomain = await this.FormsService.getQlikclouddomain();
    }

    calculatePeriodFactor() {
        var start = new Date(this.profile.tenant.formsActivationTime).getTime();
        var end = new Date(this.profile.tenant.formsExpires).getTime();
        const today = new Date().getTime();

        if (!this.profile.tenant.formsExpires || today > end) {
            this.periodProgres = 0;
        } else {
            this.periodProgres = Math.round(((today - start) / (end - start)) * 100);
        }
    }

    getFormTypeString(type) {
        return typesList[type]
    }

    clearFilter(type) {
        if (type == null) delete this.search.type
    }


    async saveNote(form) {
        try {
            var key = (await this.ApiService.post("/api/forms/" + form.formId + "/note", form)).data;
        } catch (e) {
            this.err = e.data.message;
        }

        form.edit = 0;
        await this.load();
    }

    isDbFormType(type) {
        return SQL_FORM_TYPES.includes(type);
    }

    updateDefaultChngelogValue() {
        (this.newFormData.type == 'general-sql' || this.newFormData.type == 'commentobj-sql') ? this.newFormData.changelog = false : this.newFormData.changelog = true
    }

    async updateClientId() {
        let result = await this.DialogService.confirm("Update client id to " + this.clientId + " ?");
        if (result) {
            try {
                await this.ApiService.post("/api/forms/clientid", {"clientId": this.clientId});
                this.DialogService.success("Client id updated!")
            } catch (e) {
                this.err = e.data.message;
            }
            await this.load();
        }
    }

    async deleteDatasource(ds: Datasource) {
        this.datasourcesErr = "";
        let result = await this.DialogService.confirm("Remove " + ds.id + " ? Data source and credentials will be deleted.");
        if (result) {
            try {
                await this.ApiService.delete("/api/datasources/" + ds.id);
                this.DialogService.success("Data source with id " + ds.id + " was deleted successfully.")
            } catch (e) {
                this.datasourcesErr = e.data.message;
                return;
            }
            void this.loadDatasources();
        }
    }

    async synchronizeGroups() {
        await this.ApiService.post("/api/forms/groups/synchronize", {"qlikToken": this.qlikToken});
        this.DialogService.success("User groups have been synchronized.")
    }
}
