import * as angular from "angular";
import LoginCtrl from "./LoginCtrl";
import LoginQlikCtrl from "./LoginQlikCtrl";
import config from "../config";

const module = angular.module("inphinity.app.login", [])
if (config.useUnifiedLogin) {
    module.config(function routes($stateProvider) {
        $stateProvider
            .state("login", {
                url: "/login",
                template: require("./login.html").default,
                controller: LoginCtrl,
                controllerAs: "ctrl",
                resolve: LoginCtrl.$resolve
            })
    })
}

if (config.integratedLogin) {
    module.config(function routes($stateProvider) {
        $stateProvider
            .state("login-qlik", {
                url: "/login-qlik",
                params: {
                    qlikCloudDomain: null,
                    expired: null,
                    returnTo: null
                },
                template: require("./login.qlik.html").default,
                controller: LoginQlikCtrl,
                controllerAs: "ctrl",
                resolve: LoginQlikCtrl.$resolve
            })
    })
}

export default module.name;