export default class FaqCtrl {
    static $resolve = {
        faqs: (ApiService) => ApiService.get("/api/faq").then(r => r.data)
    };

    private faqs;
    private area;
    private categories;
    private questions;
    private link;
    private $sce;


    constructor(faqs, $stateParams, $location, $timeout, $sce, private ApiService) {
        this.faqs = faqs;
        this.$sce = $sce;
        this.area = $stateParams.area || "Main info";
        this.categories = [...new Set(this.faqs.map(faq => faq.area))];
        this.questions = this.faqs;
        (this.questions[$stateParams.question] || {}).show = true;

        if ($stateParams.category && $stateParams.number) {
            $timeout(() => {
                const element = document.getElementById($stateParams.category + $stateParams.number);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "start" });
                    const question = this.findQuestion(this.area, $stateParams.category, $stateParams.number);
                    question.show = true;
                }
            }, 100);
        }
    }

    setCategory(area, $location) {
        this.area = area;
        $location.search({area: area});
    }

    toggle (q) {
        let show = q.show;
        this.questions.forEach(q => q.show = false);
        q.show = !show;
    }

    findQuestion(area, category, number) {
        return this.questions.find(question =>
            question.area === area &&
            question.category === category &&
            question.number === number
        );
    }

    share(area, category, number, $event) {
        $event.stopPropagation();
        this.link = window.location.protocol + "//" + window.location.host + "/#!/faq?area=" + encodeURIComponent(area) + "&category=" + encodeURIComponent(category) + "&number=" + encodeURIComponent(number);
        navigator.clipboard.writeText(this.link);
    }

}
