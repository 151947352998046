import {Worker} from "../../util";

export default class FormsCtrl {
    public users = [];
    public err;
    public r;
    public newUser;
    public newTenant;
    public portals = [];

    public regions = [];

    constructor(private ApiService, private DialogService, private UserService, private config) {
        this.load = Worker(this.load);
        this.deleteForm = Worker(this.deleteForm);
        this.createNewForm = Worker(this.createNewForm);
        this.load();
        this.listPortals();

        this.regions = config.allowedFormsRegions;
    }

    async load() {
        this.users = (await this.ApiService.get("/api/admin/forms")).data
    }

    async listPortals() {
        this.portals = (await this.ApiService.get("/api/admin/portals")).data
    }

    async updateFormsLimit(tenant) {
        tenant.updateLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newFormLimit
        }
        var r = (await this.ApiService.post("/api/admin/forms/limit", update)).data

        tenant.updateLimitPending = false
        await this.load();
    }

    async deleteForm(tenantId, form) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Delete " + form.formId + " ? Form and form data will be deleted.");
        if (result) {
            form.deletePending = true;
            try {
                this.r = (await this.ApiService.post("/api/admin/form/" + form.formId, {tenantId: tenantId})).data
            } catch (e) {
                this.err = e.data.message;
                form.deletePending = false;
            }
            await this.load();
        }
    }

    async createNewForm(tenant) {
        this.err = this.r = "";
        tenant.addForm = true;
        try {
            this.r = (await this.ApiService.post("/api/admin/form/create", {
                tenantId: tenant.id,
                type: tenant.newForm.type,
                changelog: tenant.newForm.changelog,
                description: tenant.newForm.description,
                region: tenant.newForm.region
            })).data;
        } catch (e) {
            this.err = e.data.message;
        }
        tenant.addForm = false;

        await this.load();
    }

    async startFormsTrial(tenant) {
        tenant.startFormsTrialPending = true;
        var r = (await this.ApiService.post("/api/admin/form/" + tenant.id + "/starttrial", null)).data
        tenant.startFormsTrialPending = false
        await this.load();
    }
}
