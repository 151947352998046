import * as Base64 from "base64-js";
import {Worker} from "../util";
import AuthService from "../auth/AuthService";
import ClientConfig from "../config/ClientConfig";
import SettingsService from "../settings/SettingsService"

const CODE_VERIFIER_KEY = "qlik-login-code-verifier";
const RECENT_LIST_KEY = "qlik-login-recent-list";
const RETURN_TO_KEY = "qlik-login-return-to";

type LoginState = "UNINITIALIZED" | "SELECT_DOMAIN" | "REDIRECTING" | "LOGGING_IN" | "LOGGED_IN" | "ERROR"


export default class LoginQlikCtrl {

    public static $resolve = {}

    public qlikDomain = "";
    public recentList = [];
    public state: LoginState = "UNINITIALIZED";

    constructor(
        private AuthService: AuthService,
        private config: ClientConfig,
        private SettingsService:
        SettingsService,
        private $stateParams: {
            qlikCloudDomain?: string
            returnTo?: string
            expired?: boolean
        }
    ) {
        this.initialize = Worker(this.initialize.bind(this));
        try {
            this.initialize().then(null, (error) => {
                this.state = "ERROR";
            });
        } catch (e) {
            this.state = "ERROR";
        }
    }

    private async initialize() {
        if (!this.config.integratedLogin) throw new Error("Integrated login is not enabled");
        try {
            this.recentList = JSON.parse(window.localStorage.getItem(RECENT_LIST_KEY) || "[]");
        } catch (e) {
            console.log("Failed to load recent list", e)
        }
        const result = this.parseFragment();
        if (this.$stateParams.qlikCloudDomain) {
            await this.login(this.$stateParams.qlikCloudDomain);
        } else if (result?.code) {
            await this.requestToken(result.code);
        } else if (result?.error) {
            throw new Error(result.error_description ?? "Authentication error: " + result.error);
        } else if (await this.AuthService.waitLogin()) {
            this.state = "LOGGED_IN";
        } else {
            this.state = "SELECT_DOMAIN";
        }
    }

    async login(domain: string) {
        this.state = "REDIRECTING";
        domain = cleanQlikDomain(domain);
        try {
            if (!this.recentList.includes(domain)) this.recentList.push(domain);
            window.localStorage.setItem(RECENT_LIST_KEY, JSON.stringify(this.recentList));
        } catch (e) {
            console.log("failed to save recent list", e)
        }
        try {
            if (this.$stateParams.returnTo) {
                window.sessionStorage.setItem(RETURN_TO_KEY, this.$stateParams.returnTo);
            }
        } catch (e) {
            console.log("failed to save return URL", e);
        }
        const codeChallenge = await this.createCodeChallenge();
        const url = new URL(this.config.integratedLogin.authorizationEndpoint);
        const params = {
            "client_id": this.config.integratedLogin.clientId,
            "tenantDomain": domain,
            "response_mode": "fragment",
            "response_type": "code",
            "code_challenge": codeChallenge,
            "code_challenge_method": "S256",
            "redirect_uri": this.getRedirectUri(),
        }
        Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value))
        window.location.href = url.toString();
    }

    private parseFragment() {
        const query = window.location.hash.split("?")[1] ?? "";
        const params = new URLSearchParams(query);
        window.location.hash = window.location.hash.split("?")[0];
        if (params.has("error")) {
            return {error: params.get("error"), error_description: params.get("error_description")}
        } else if (params.has("code")) {
            return {code: params.get("code")}
        }
    }

    private getRedirectUri() {
        return window.location.origin + "/";
    }

    private async requestToken(code: string) {
        this.state = "LOGGING_IN";
        let response = await fetch(this.config.integratedLogin.tokenEndpoint,
            {
                method: "POST",
                body: new URLSearchParams(Object.entries({
                    "grant_type": "authorization_code",
                    code,
                    client_id: this.config.integratedLogin.clientId,
                    code_verifier: this.getStoredCodeVerifier(),
                    redirect_uri: this.getRedirectUri()
                })),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                signal: AbortSignal.timeout(30_000)
            }
        );
        if (response.status === 200) {
            const responseJson: OAuthTokenResponse = await response.json();
            if ("access_token" in responseJson) {
                await this.AuthService.integratedLogin(responseJson.access_token);
                // force loading profile after login
                void this.SettingsService.getProfile();
                this.state = "LOGGED_IN";
                try {
                    const returnTo = window.sessionStorage.getItem(RETURN_TO_KEY)
                    window.sessionStorage.removeItem(RETURN_TO_KEY);
                    if (returnTo) {
                        window.location.hash = returnTo;
                    } else if (this.config.afterLoginPage) {
                        window.location.hash = "#!" + this.config.afterLoginPage
                    }
                } catch (e) {
                    console.log("Failed to restore returnTo route")
                }
            } else {
                throw new Error("Unexpected response - did not contain access_token: " + JSON.stringify(responseJson));
            }
        } else {
            const responseJson: OAuthTokenResponse = await response.json();
            if ("detail" in responseJson) {
                throw new Error(`Failed to obtain access token: ${response.status} ${response.statusText} - ${responseJson.detail}`);
            } else if ("error_description" in responseJson) {
                throw new Error(`Failed to obtain access token: ${response.status} ${response.statusText} - ${responseJson.error_description}`);
            } else {
                console.error(responseJson);
                throw new Error(`Failed to obtain access token: ${response.status} ${response.statusText} - see console for more details`);
            }
        }
    }

    getStoredCodeVerifier() {
        try {
            const result = window.localStorage.getItem(CODE_VERIFIER_KEY);
            if (result === null) throw new Error("Code verifier not found in local storage");
            window.localStorage.removeItem(CODE_VERIFIER_KEY);
            return result;
        } catch (e) {
            console.error(e);
            throw new Error("Failed to load OAuth code verifier from local storage");
        }
    }

    async createCodeChallenge(): Promise<string> {
        try {
            const codeVerifier = Base64.fromByteArray(window.crypto.getRandomValues(new Uint8Array(32)));
            window.localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
            const hash = await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(codeVerifier));
            return PKCEBase64Encode(new Uint8Array(hash));
        } catch (e) {
            console.log(e);
            throw new Error("Failed to generate code challenge for OAuth");
        }
    }

    removeRecent(domain: string) {
        this.recentList = this.recentList.filter(i => i != domain);
        window.localStorage.setItem("qlik-login-recent-list", JSON.stringify(this.recentList));
    }

    reset() {
        this.state = "SELECT_DOMAIN";
        window.localStorage.removeItem(CODE_VERIFIER_KEY)
        window.location.hash = window.location.hash.split("?")[0];
    }

    logout() {
        this.state = "UNINITIALIZED";
        this.AuthService.logout();
    }

    switchTenant() {
        this.state = "SELECT_DOMAIN";
    }

}

function PKCEBase64Encode(data: Uint8Array) {
    return Base64.fromByteArray(data)
        .replace(/\//g, '_') // url-safe base64 variant
        .replace(/\+/g, '-')
        .replace(/=+$/, ''); // remove padding
}

function cleanQlikDomain(domain: string) {
    return domain.trim()
        .replace(/^https?:\/\//, "")
        .replace(/\/.*$/, "");
}

type OAuthTokenResponse = { token_type: string, access_token: string } | { error: string, error_description: string };