import DialogService from "../dialogs/DialogService";
import config from "../config";

let loginHash = null;
let loginErrorHash = null;
const hashParams = new URLSearchParams(window.location.hash?.substring(1) || "");
if (hashParams.has("access_token")) {
    loginHash = window.location.hash;
} else if (window.location.hash.includes("access_denied") || hashParams.get("error") == "unauthorized") {
    loginErrorHash = window.location.hash;
} else if (hashParams.has("code") || hashParams.has("error")) {
    window.location.hash = "#!/login-qlik?" + hashParams.toString();
}

interface Auth0User {
    sub: string,
    nickname: string,
    name: string,
    picture: string,
    updated_at: string,
    email: string,
    email_verified: boolean,
    "https://qlik.com/groups": string[]
}

interface QlikAuthUser {
    sub: string,
    name: string,
    email: string,
    qlikCloudDomain: string
    picture?: string
    isIntegratedLogin: boolean
    exp: number // timestamp in seconds since epoch
}

export type UserClaims = Auth0User | QlikAuthUser;

/**
 * This class provides access to
 *  - access token - for API calls
 *  - user - identity provide supplied info
 **/

export default class AuthService {
    private provider;
    public expiresAt: number; // millisecond timestamp
    private accessToken: string;
    private user: UserClaims;
    private defferedLogin;

    constructor(angularAuth0, private $state, private $timeout, private $rootScope, $q, private DialogService: DialogService, private $http) {
        this.provider = angularAuth0;
        this.defferedLogin = $q.defer();
    }

    async run() {
        onSessionCleared(() => {
            this.logout()
        })
        if (loginErrorHash) {
            if (loginErrorHash.includes("user%20is%20blocked")) {
                void this.DialogService.error("User account is disabled");
            }
            //@TODO handle login error
        }
        if (!loginHash) {
            if (await this.loadFromSession()) return;
            void this.renewTokens();
        } else {
            this.handleAuthentication(loginHash);
        }

    }

    handleAuthentication(hash) {
        this.provider.parseHash({hash: hash}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.localLogin(authResult);
            } else if (err) {
                this.defferedLogin.resolve(false);
            }
        });
    }

    // receive auth token from login
    localLogin(authResult) {
        this.expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        this.accessToken = authResult.accessToken;
        try {
            saveSession({
                accessToken: this.accessToken,
                provider: "auth0",
                expiresAt: this.expiresAt
            })
        } catch (e) {
            console.warn("Failed to store token to session storage")
        }
        if (authResult?.appState?.locationHash) {
            if (config.home && authResult.appState.locationHash === "#!" + config.home && config.afterLoginPage) {
                window.location.hash = "#!" + config.afterLoginPage
            } else {
                window.location.hash = authResult.appState.locationHash
            }
        }
        this.provider.client.userInfo(this.accessToken, (err, user) => {
            this.user = user;
            this.defferedLogin.resolve(this.user);
            this.$rootScope.$digest();
        });
    }

    // load auth token from session storage
    async loadFromSession(): Promise<boolean> {
        try {
            const session = loadSession();
            if (!session.accessToken) return false;
            const auth0login = await new Promise<boolean>((resolve, reject) => {
                this.provider.client.userInfo(session.accessToken, (err, user) => {
                    if (user && !err) {
                        console.log("loaded session from session storage", user)
                        this.user = user;
                        this.accessToken = session.accessToken;
                        this.expiresAt = session.expiresAt;
                        this.defferedLogin.resolve(this.user);
                        this.$rootScope.$digest();
                        resolve(true);
                    } else {
                        console.log("session invalid");
                        resolve(false);
                    }
                })
            })
            if (!auth0login) {
                await this.integratedLogin(session.accessToken);
            }
            return true;
        } catch (e) {
            console.warn("failed to load token from session storage");
        }
        return false;
    }

    // resume session after reload (from session storage or by silent login)
    async renewTokens() {
        console.log("token expired, renew login");
        const session = loadSession();
        if (session.provider === "integrated") {
            clearSession();
            this.accessToken = undefined;
            this.expiresAt = 0;
            this.$state.go("login-qlik", {expired: true, returnTo: window.location.hash})
            this.defferedLogin.resolve(false);
        } else {
            this.provider.checkSession({},
                async (err, result) => {
                    if (err) {
                        console.log("login error", err);
                        if (err.error == "access_denied" && err.error_description == "Signup disabled") {
                            await this.DialogService.warning("Registration of new user accounts opens in June 2019.");
                            this.logout();
                        }
                        this.defferedLogin.resolve(false);
                    } else {
                        this.localLogin(result);
                    }
                }
            );
        }
    }

    logout() {
        this.accessToken = '';
        this.expiresAt = 0;
        this.user = null;
        try {
            clearSession();
        } catch (e) {

        }
        this.provider.logout({returnTo: window.location.protocol + "//" + window.location.host});
    }

    isLoggedIn() {
        return !!this.user;
    }

    login(targetLocationHash?: string) {
        this.provider.authorize({
            appState: {
                locationHash: targetLocationHash || window.location.hash
            }
        });
    }

    // returns a promise that resolves to user account (identity info) when available
    // if user is not logged in, promise rejects
    async waitLogin(): Promise<UserClaims | false> {
        if (this.accessToken && Date.now() > this.expiresAt) {
            await this.renewTokens();
        }
        return this.defferedLogin.promise;
    }

    // returns user identity info if exists
    getUser() {
        return this.user;
    }

    getAccessToken() {
        return this.accessToken;
    }

    async integratedLogin(accessToken: string) {
        this.accessToken = accessToken;
        const response = await this.$http.get("/api/user/me/claims", {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
        this.user = response.data;
        this.expiresAt = response.data.exp * 1000;
        saveSession({
            accessToken: this.accessToken,
            provider: "integrated",
            expiresAt: this.expiresAt
        })
        this.defferedLogin.resolve(this.user);
        try {
            this.$rootScope.$digest();
        } catch (e) {
            // ignore digest in progress
        }
    }

}

interface Session {
    accessToken?: string
    provider?: "auth0" | "integrated"
    expiresAt: number
}

function loadSession(): Session {
    const provider = localStorage.getItem("inphinity_token_provider");
    return {
        accessToken: localStorage.getItem("inphinity_token"),
        provider: (provider === "auth0" || provider === "integrated") ? provider : undefined,
        expiresAt: +(localStorage.getItem("inphinity_token_exp") ?? "0")
    }
}

function saveSession(session: Session) {
    localStorage.setItem("inphinity_token", session.accessToken)
    localStorage.setItem("inphinity_token_provider", session.provider)
    localStorage.setItem("inphinity_token_exp", session.expiresAt.toString())
}

function clearSession() {
    localStorage.removeItem("inphinity_token")
    localStorage.removeItem("inphinity_token_exp")
    localStorage.removeItem("inphinity_token_provider")
}

function onSessionCleared(handler: (event: StorageEvent) => void) {
    addEventListener("storage", (event) => {
        if (event.key === "inphinity_token" && !event.newValue) {
            handler(event);
        }
    })
}